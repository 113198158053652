<template>
  <MainPanel>
    <a-card style="width: 100%; min-height: 100vh;">
      <a-layout>

        <a-layout>
          <a-layout-content :style="{ background: '#fff', minHeight: '400px' }">

            <a-row :gutter="24">
              <a-col :span="24">
                <a-space>
                  <a-button size="small" type="dashed" v-for="(receiveUser, index) in receiveUserList" :key="index" @click="cancelReceiveUser(index)">
                    <a-avatar :src="receiveUser.avatar" size="small" style="color: #f56a00; background-color: #fde3cf">
                      <template #icon><UserOutlined /></template>
                    </a-avatar>
                    {{ receiveUser.title }}
                  </a-button>
                </a-space>
              </a-col>
            </a-row>

            <div id="talk_show">

              <div v-for="(messageData,index) in messageDataList" :key="index">
                <div class="send_time">
                  <sub>{{messageData.createTime}}</sub>
                </div>

                <div class="send_talk" v-if="messageData.status === 0">
                  <a-space>
                    <sup>{{messageData.realname}}</sup>
                    <a-avatar size="large" class="talk_avatar" :src="messageData.avatar">
                      <template #icon><UserOutlined /></template>
                    </a-avatar>
                  </a-space>
                  <br/>
                  <span class="send_talk_span">
                    <md-editor
                            :modelValue="messageData.message"
                            :toolbars="toolbars"
                            :showCodeRowNumber="true"
                            :readOnly="true"
                            theme="light"
                            :editorId="`chat${index}`"
                    />
                  </span>
                </div>

                <div class="receive_talk" v-if="messageData.status === 1">
                  <a-space>
                    <a-avatar size="large" class="talk_avatar" :src="messageData.avatar">
                      <template #icon><UserOutlined /></template>
                    </a-avatar>
                    <sup>{{messageData.realname}}</sup>
                  </a-space>
                  <br/>
                  <span class="receive_talk_span">
                    <md-editor
                            :modelValue="messageData.message"
                            :toolbars="toolbars"
                            :showCodeRowNumber="true"
                            :readOnly="true"
                            theme="light"
                            :editorId="`chat${index}`"
                    />
                  </span>
                </div>

              </div>

            </div>

            <div style="padding-right: 20px;">
              <md-editor
                      v-model="message"
                      :toolbars="sendToolbars"
                      style="width: 100%; height: 400px;"
                      theme="light"
                      :showCodeRowNumber="true"
                      :readOnly="false"
                      @onUploadImg="onUploadImg"
                      @onSave="send"
                      editorId="send-chat"
              />
            </div>

          </a-layout-content>
        </a-layout>

        <a-layout-sider theme="light" width="400" style="margin-left: 1px; padding-left:20px;">
          <p v-if="sessionInfo === ''">未连接</p>
          <a-collapse activeKey="1" v-else>
            <a-collapse-panel key="1" header="已连接">
              <p>{{ sessionInfo }}</p>
            </a-collapse-panel>
          </a-collapse>

          <a-input v-model:value="searchValue" style="margin-bottom: 20px" placeholder="搜索" />

          <a-tree :showLine="true"
                  v-model:expandedKeys="expandedKeys"
                  :tree-data="treeData"
                  @select="selectReceiveUser"
          >
            <template #title="{ title, avatar, roleName }">
              <a-avatar :src="avatar" size="small" style="color: #f56a00; background-color: #fde3cf" v-if="avatar !== null">
                <template #icon><UserOutlined /></template>
              </a-avatar>
              <a-space>
                <span v-if="title.indexOf(searchValue) > -1">
                  {{ title.substr(0, title.indexOf(searchValue)) }}
                  <span style="color: #f50">{{ searchValue }}</span>
                  {{ title.substr(title.indexOf(searchValue) + searchValue.length) }}
                </span>
                <span v-else>
                  {{ title }}
                </span>
                <span v-if="roleName !== null">
                  <sup style="color: navy" v-if="roleName.includes(',')">{{ roleName.split(",").map((obj, index) => ++index + '. ' + obj).join("&nbsp;&nbsp;&nbsp;") }}</sup>
                  <sup style="color: lightseagreen" v-else>{{ roleName }}</sup>
                </span>
              </a-space>
            </template>
          </a-tree>

        </a-layout-sider>

      </a-layout>
    </a-card>
  </MainPanel>
</template>

<script>
  import MainPanel from "@/components/basic/MainPanel";
  import {message} from "ant-design-vue";

  import MdEditor from 'md-editor-v3';
  import 'md-editor-v3/lib/style.css';

  import * as DeptApi from '@/api/DeptApi.js';
  import * as ChatApi from '@/api/ChatApi.js';

  export default {
    name: 'Chat',
    components: {
      MainPanel,
      MdEditor,
    },
    data() {
      return {
        treeData: [],
        expandedKeys: [],

        searchValue: '',

        receiveUserList: [],

        receiveUserIdList: [],
        message: '',

        socket: {},
        messageDataList: [],

        toolbars: ['=', 'pageFullscreen', 'catalog'],

        sendToolbars: [
          'save',
          '-',
          'image',
          'link',
          '-',
          'quote',
          'title',
          'bold',
          'italic',
          'underline',
          'strikeThrough',
          '-',
          'codeRow',
          'code',
          '-',
          'table',
          'orderedList',
          'unorderedList',
          '-',
          'katex',
          'sup',
          'sub',
          '-',
          'mermaid',
          '-',
          'revoke',
          'next',
          '=',
          'preview',
          'htmlPreview',
          '-',
          'pageFullscreen',
          'catalog',
        ],

        myTimer: '',

        sessionInfo: '',

      }
    },

    methods: {
      findDeptMember() {
        DeptApi['findDeptMemberApi']()
          .then((result) => {
            let code = result.data.code;
            let msg = result.data.msg;
            if (code === 200) {
              this.treeData = result.data.data['treeData'];
              this.expandedKeys = result.data.data['expandedKeys'];
            } else {
              message.error(msg);
            }

          })
          .catch((e) => {
            message.error(e.message);
          })
      },

      selectReceiveUser(obj, e) {
        // console.log(e.node.title, e.node.key);
        let key = e.node.key;
        let title = e.node.title;
        let avatar = e.node.avatar;

        let isExist = false;
        // eslint-disable-next-line no-unused-vars
        this.receiveUserList.forEach((receiveUser, index) => {
          if (receiveUser.key === key) {
            isExist = true;
            // 存在则删除
            // this.receiveUserList.splice(index, 1);
            // this.receiveUserIdList.splice(index, 1);
          }
        });
        if (!isExist) {
          this.receiveUserList.push({
            title: title,
            key: key,
            avatar: avatar,
          });
          this.receiveUserIdList.push(key);
        }

      },

      cancelReceiveUser(index) {
        this.receiveUserList.splice(index, 1);
        this.receiveUserIdList.splice(index, 1);
      },

      onUploadImg(obj) {
        console.log(obj)
      },

      send() {
        if (this.sessionInfo === '') {
          message.error("未连接或已断开");
          return;
        }
        let params = {receiveUserIdList: this.receiveUserIdList, message: this.message}
        ChatApi['chatSendApi'](params)
          .then((result) => {
            let code = result.data.code;
            let msg = result.data.msg;
            if (code === 200) {
              const createTime = new Date();
              const avatar = sessionStorage.getItem('avatar');
              const realname = sessionStorage.getItem('realname');
              this.messageDataList.push({
                createTime: createTime,
                status: 0,
                avatar: avatar,
                realname: realname,
                message: this.message,
              })
              this.message = '';
            } else {
              message.error(msg);
            }

          })
          .catch((e) => {
            message.error(e.message);
          })
      },

      onopen() {
        console.log("---连接已建立---");
        this.myTimer = setInterval(() => {
          // ping
          this.socket.send("");
        }, 50000);
      },

      onmessage(e) {
        // console.log("---接收---", e['data']);
        let receive = e['data'];
        if (receive === '') {
          // pong
          return;
        }
        if (receive.startsWith("^_^ | ws-chat")) {
          this.sessionInfo = receive;
        } else {
          let {createTime, avatar, realname, message} = JSON.parse(receive);
          this.messageDataList.push({
            createTime: createTime,
            status: 1,
            avatar: avatar,
            realname: realname,
            message: message,
          })
        }

      },

      onclose() {
        console.log("---连接关闭---");
        this.sessionInfo = '';
        clearInterval(this.myTimer);
        this.initWebSocket();
      },

      initWebSocket() {
        let token = sessionStorage.getItem("token")
        let companyId = sessionStorage.getItem("companyId")
        let roleId = sessionStorage.getItem("roleId")
        this.socket = new WebSocket("wss://saas.julycloud.cn/api/ws-chat?authorization=" + token + "&companyId=" + companyId + "&roleId=" + roleId);
        this.socket.onopen = this.onopen;
        this.socket.onmessage = this.onmessage;
        this.socket.onclose = this.onclose;
      },

    },

    created() {
      this.findDeptMember();

      this.initWebSocket();
    },

    mounted() {

    }
  }
</script>

<style>
  #talk_show {
    width: 100%;
    height: 600px;
    border: 0px solid #a55fe5;
    overflow: auto;
    padding: 10px;
  }

  .send_time {
    text-align: center;
  }

  .talk_avatar {
    color: #f56a00;
    background-color: #fde3cf;
  }

  .send_talk {
    margin: 10px;
    text-align: right;
  }
  .send_talk_span {
    display: inline-block;
    background: #561cb5;
    border-radius: 10px;
    color: #fff;
    padding: 1px 1px;
  }

  .receive_talk {
    margin: 10px;
    text-align: left;
  }
  .receive_talk_span {
    display: inline-block;
    background: #097b6a;
    border-radius: 10px;
    color: #fff;
    padding: 1px 1px;
  }

  /*隐藏原文档*/
  [id^="chat"] .md-editor-content .md-editor-input-wrapper {
    display: none;
  }
  /*隐藏同步滚动*/
  [id^="chat"] .md-editor-footer-right {
    display: none;
  }

  /*设置文档宽*/
  [id^="chat"] .md-editor-content {
    padding-left: 20%;
    padding-right: 20%;
    text-align: left;
    min-width: 60vh;
  }
  /*设置文档高*/
  [id^="chat"] {
    height: auto;
  }

  /*设置目录宽*/
  [id^="chat"] .md-editor-catalog-editor {
    width: 20%;
    text-align: left;
  }

  /*隐藏同步滚动*/
  #send-chat .md-editor-footer-right {
    display: none;
  }

  /*设置文档宽*/
  #send-chat .md-editor-content {
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
  }

  /*设置目录宽*/
  #send-chat .md-editor-catalog-editor {
    width: 50%;
  }
</style>
